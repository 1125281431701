import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { FaChartArea, FaGlobe, FaHammer, FaWrench } from "react-icons/fa";
const Advantages = () => {
    return (
        <>
        <SectionTitle subtitle={"WHO WE ARE"} title={"Our Advantages"} />
        <div className="row row-cols-1 row-cols-md-4 px-5">
          <div className="d-flex flex-column align-items-center text-center justify-content-center p-5 gap-3">
            <span
              className="p-4 rounded-circle"
              style={{ backgroundColor: "#007aff" }}
            >
              <FaWrench size={48} color="white" />
            </span>
            <h3>Spare Parts Supply</h3>
            <p>
              We provide a wide range of spare parts quickly and reliably for
              all your ship maintenance and repair needs.
            </p>
          </div>
          <div className="d-flex flex-column align-items-center text-center justify-content-center p-5 gap-3">
            <span
              className="p-4 rounded-circle"
              style={{ backgroundColor: "#007aff" }}
            >
              <FaHammer size={48} color="white" />
            </span>
            <h3>Maintenance</h3>
            <p>
              We offer regular maintenance services with our expert team to
              ensure your ship operates smoothly and has a long lifespan.
            </p>
          </div>
          <div className="d-flex flex-column align-items-center text-center justify-content-center p-5 gap-3">
            <span
              className="p-4 rounded-circle"
              style={{ backgroundColor: "#007aff" }}
            >
              <FaChartArea size={48} color="white" />
            </span>
            <h3>Technical Consultation</h3>
            <p>
              We provide professional consultation services to optimize your
              ship operations and offer quick solutions to your technical
              problems.
            </p>
          </div>
          <div className="d-flex flex-column align-items-center text-center justify-content-center p-5 gap-3">
            <span
              className="p-4 rounded-circle"
              style={{ backgroundColor: "#007aff" }}
            >
              <FaGlobe size={48} color="white" />
            </span>
            <h3>International Logistics</h3>
            <p>
              We deliver your spare parts and equipment safely and on time to
              anywhere in the world.
            </p>
          </div>
        </div>
      </>
    )
}

export default Advantages;