import React from 'react';
import CountUp from 'react-countup';
import Shape from '../../images/funfact.png'

const FunFact = (props) => {

    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-fun-fact-grids clearfix">
                            <div className="grid">
                                <div className="info">
                                    <h3><CountUp end={50} enableScrollSpy /></h3>
                                    <p>COUNTRIES AND REGIONS SERVED</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><CountUp end={10000} enableScrollSpy /></h3>
                                    <p>SERVICES CASES</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><CountUp end={100} enableScrollSpy /></h3>
                                    <p>COOPERATED MAKERS AND BRANDS</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 style={{fontSize : "40px"}}><CountUp end={270000} enableScrollSpy />m2</h3>
                                    <p>WAREHOUSE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape">
                <img src={Shape} alt="" />
            </div>
        </section>
    )

}

export default FunFact;




