import img from "../images/slider/shape.png";
import twostrokeimg from "../images/deutz.webp";
import generatorimg from "../images/generator.png";
import hatchimg from "../images/hatch.png";
import boilerimg from "../images/boiler.png";
import seperatorimg from "../images/seperator.png";
import pumpimg from "../images/pump.png";
import turboimg from "../images/turbo.png";
import airimg from "../images/air.png";

const Projects = [
  {
    id: "01",
    subtitle: "PROMARINESOLUTIONS",
    title: "Two  Stroke Diesel Engine",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions",
    pimg: twostrokeimg,
    pSimg: twostrokeimg, // Assuming you want both primary and secondary images to be the same
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "02",
    subtitle: "PROMARINESOLUTIONS",
    title: "Generator Sets / Aux. Engine",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions2",
    pimg: generatorimg,
    pSimg: generatorimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "03",
    subtitle: "PROMARINESOLUTIONS",
    title: "Hatch Cover",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions3",
    pimg: hatchimg,
    pSimg: hatchimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "04",
    subtitle: "PROMARINESOLUTIONS",
    title: "Boiler Incinerator",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions4",
    pimg: boilerimg,
    pSimg: boilerimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "05",
    subtitle: "PROMARINESOLUTIONS",
    title: "Seperator / Heat Exchanger",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions5",
    pimg: seperatorimg,
    pSimg: seperatorimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "06",
    subtitle: "PROMARINESOLUTIONS",
    title: "Pump & Valve",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions6",
    pimg: pumpimg,
    pSimg: pumpimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "07",
    subtitle: "PROMARINESOLUTIONS",
    title: "Turbocharger",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions7",
    pimg: turboimg,
    pSimg: turboimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
  {
    id: "08",
    subtitle: "PROMARINESOLUTIONS",
    title: "Air Compressor",
    docomunt:
      "Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit",
    slug: "Special-Transport-Solutions8",
    pimg: airimg,
    pSimg: airimg,
    location: "7 Lake Street,London",
    date: "15 Dec 2024",
  },
];

export default Projects;
