import React, { Fragment } from "react";
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import CtaSection from "../../components/CtaSection/CtaSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/promarine.png";
import Advantages from "../Advantages/Advantages";
import FunFact from "../../components/FunFact/FunFact";
import PartCard from "../4PartCard";
import shape from "../../images/shape.jpeg";

const AboutPage = () => {
  return (
    <Fragment>
      <HeaderTop />
      <Navbar hclass={"wpo-site-header"} Logo={Logo} />
      <PageTitle pageTitle={"About Us"} pagesub={"About"} />
      <div className="container d-flex flex-column flex-lg-row align-items-center justify-content-center py-5 gap-5">
        <div className="col-lg-8 col-md-10 text-center text-lg-start p-3">
          <h1 style={{ fontWeight: "bold" }} >Pro Marine Solutions</h1>
          <p>
            We, as a team deeply rooted in the maritime industry, have been
            serving you for many years with extensive experience in this field.
            Founded as a company that recognizes the value of time, especially
            in spare parts and service operations within the maritime sector, we
            aim to address all challenges prevalent in the industry.
            <br />
            <br />
            Our goal is to deliver high-quality spare parts to you in the best
            possible manner and subsequently support you through our service
            offerings. What distinguishes us from other companies is our
            greatest asset – a robust team that has spent years in various
            positions within this sector. This team understands the critical
            importance of speed and quality, and they go above and beyond to
            provide you with the best possible service.
            <br />
            <br />
            Additionally, we would like to highlight that we have a dedicated
            stock of spare parts in China and possess the capability for
            manufacturing, further enhancing our commitment to meeting your
            maritime needs.
          </p>
          <div className="text-center text-lg-start">
            <a href="#" className="btn btn-primary theme-btn">
              Get In Touch
            </a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex justify-content-center p-3">
          <img
            src={shape}
            className="img-fluid rounded"
            style={{ width: "525px", height: "395px", objectFit: "cover" }}
            alt="Marine shape"
          />
        </div>
      </div>
      <Advantages />
      <FunFact hclass={"wpo-fun-fact-section"} />
      <PartCard />
      <CtaSection />
      <PartnersSection CollClass={"col col-xs-12"} />
      <Footer hclass={"wpo-site-footer"} upperContactArea={true} />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
