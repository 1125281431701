import React from "react";
import { FaPencilAlt, FaCloud, FaCheckCircle, FaGlobe } from "react-icons/fa"; // Import additional icons if needed

const PartCard = () => {
  return (
    <div className="row row-cols-1 row-cols-md-4">
      <div
        className="d-flex flex-column gap-3 p-5 align-items-center justify-content-center"
        style={{ backgroundColor: "#F7F6F2", maxWidth:"97.5%" }}
      >
        <span>
          <FaPencilAlt size={48} color="#DB5045" />
        </span>
        <h3 style={{ color: "black" }}>Customized Solutions</h3>
        <p style={{ color: "black" }}>
          We offer solutions tailored to the specific needs of our customers.
          Our ship spare parts and maintenance services are completely
          customized for you.
        </p>
      </div>
      <div
        className="d-flex flex-column gap-3 p-5 align-items-center justify-content-center"
        style={{ backgroundColor: "#F0E5CF", maxWidth:"97.5%" }}
      >
        <span>
          <FaCloud size={48} color="#DB5045" />
        </span>
        <h3 style={{ color: "black" }}>Innovative Technology</h3>
        <p style={{ color: "black" }}>
          With our systems equipped with the latest technology and digital solutions,
          we make your ship operations more efficient and seamless.
        </p>
      </div>
      <div
        className="d-flex flex-column gap-3 p-5 align-items-center justify-content-center"
        style={{ backgroundColor: "#1F2D37", maxWidth:"97.5%" }}
      >
        <span>
          <FaCheckCircle size={48} color="#DB5045" />
        </span>
        <h3 style={{ color: "white" }}>Quality Assurance</h3>
        <p style={{ color: "white" }}>
          We rigorously test all the spare parts we supply and the services we provide
          to ensure they meet the highest industry standards, guaranteeing reliability
          for your ship.
        </p>
      </div>
      <div
        className="d-flex flex-column gap-3 p-5 align-items-center justify-content-center"
        style={{ backgroundColor: "#DB5045", maxWidth:"97.5%" }}
      >
        <span>
          <FaGlobe size={48} color="white" />
        </span>
        <h3 style={{ color: "white" }}>Global Reach</h3>
        <p style={{ color: "white" }}>
          With our global supply chain, we rapidly deliver ship spare parts to all corners
          of the world. We are your global partner in the maritime industry.
        </p>
      </div>
    </div>
  );
};

export default PartCard;
