import React from 'react';
import { Link } from 'react-router-dom';

// image
import Ab from '../../images/maintenance2.jpeg';
import Abd1 from '../../images/maintenance.jpeg';

const About = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <div className="wpo-about-img-left">
                                    {/* Set width and height inline */}
                                    <img src={Ab} alt="" style={{ width: '600px', height: '752px', objectFit: 'cover' }} />
                                </div>
                                <div className="about-img-inner">
                                    <div className="about-img-inner-text">
                                        <p>We have been providing expert maintenance services for decades.</p>
                                    </div>
                                    {/* Set width and height for the second image */}
                                    <img src={Abd1} alt="" style={{ width: '500px', height: '500px', objectFit: 'cover' }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-about-content-top">
                                    <h2>Expert Maintenance Services for Maritime Operations</h2>
                                    <p>With decades of experience, we ensure the longevity and performance of your vessels through comprehensive maintenance services.</p>
                                </div>
                                <div className="wpo-about-content-progress">
                                    {/* <div className="progress-inner">
                                        <div className="progress yellow">
                                            <span className="progress-left">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">100%</div>
                                            <div className="progress-name"><span>Success</span></div>
                                        </div>
                                    </div> */}
                                    <p>We maintain a high success rate in our maintenance projects, ensuring vessels perform at their best.</p>
                                </div>
                                <div className="wpo-about-check-wrap">
                                    <div className="wpo-about-check-item">
                                        <p>Hull Cleaning & Propeller Polishing</p>
                                    </div>
                                    <div className="wpo-about-check-item">
                                        <p>Underwater Welding</p>
                                    </div>
                                    <div className="wpo-about-check-item">
                                        <p>Technical Maintenance Services</p>
                                    </div>
                                    <div className="wpo-about-check-item">
                                        <p>Safety & Automation Services</p>
                                    </div>
                                </div>
                                <Link onClick={ClickHandler} to="/business-scope#maintenance" className="theme-btn-s2">About Maintenance</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
