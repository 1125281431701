import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ShopProduct = ({ products }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    // Modal'ı açar ve tıklanan ürünü set eder
    const openModal = (product) => {
        setSelectedProduct(product);
        setModalIsOpen(true);
    };

    // Modal'ı kapatır
    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedProduct(null);
    };

    return (
        <div>
            <div className="shop-grids clearfix">
                {products.length > 0 &&
                    products.slice(0, 8).map((product, pitem) => (
                        <div className="grid" key={pitem} onClick={() => openModal(product)}>
                            <div className="img-holder">
                                <img src={product.proImg} alt="" />
                            </div>
                            <div className="details">
                                <h3>{product.title}</h3>
                            </div>
                        </div>
                    ))}
            </div>

            {/* Material UI Modal */}
            <Modal
                open={modalIsOpen}
                onClose={closeModal}
                aria-labelledby="product-modal-title"
                aria-describedby="product-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        onClick={closeModal}
                        sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedProduct && (
                        <div className="modal-content">
                            <Typography id="product-modal-title" variant="h5" component="h2">
                                {selectedProduct.title}
                            </Typography>
                            <img
                                src={selectedProduct.excelList}
                                alt={selectedProduct.title}
                                style={{ width: '100%', height: 'auto', marginTop: '20px' }}
                            />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

// modalStyle değişkenini tanımlıyoruz
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
    outline: 'none'
};

export default ShopProduct;
