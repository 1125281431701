import React from 'react';

const ContactMap = () => {
    return (
        <section className="wpo-contact-map-section">
            <div className="wpo-contact-map">
                <iframe title='comtact-map' src="https://maps.google.com/maps?q=Evliya%20%C3%87elebi%20Mah.%20Nazan%20Sok.%20No%3A12%20K%3A3%2034940%20Tuzla%20%2F%20%C4%B0stanbul&t=m&z=13&output=embed&iwloc=near"></iframe>
            </div>
        </section>
    );
};

export default ContactMap;