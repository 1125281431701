const MarineSolutions = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="why-choose-wrap">
                    <div className="why-choose-left">
                      <h2>Marine Consultancy & Survey Services</h2>
                      <p>
                        We offer a wide range of marine consultancy and survey
                        services to ensure the optimal performance and safety of
                        your maritime operations.
                      </p>
                    </div>
                    <div className="d-flex flex-column flex-md-row">
                      <div className="why-choose-right me-md-3">
                        <div className="wpo-choose-check-wrap">
                          <div className="wpo-choose-check-item">
                            <p>Damage Surveys</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>Pre-purchase & Condition Surveys</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>Pre-docking Inspections</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>
                              Supervising Condition Assessment Programme (CAP)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="why-choose-right">
                        <div className="wpo-choose-check-wrap">
                          <div className="wpo-choose-check-item">
                            <p>Management of New Buildings</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>Superintendency during Dry-dock</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>Supervising Hull and Machinery Repairs</p>
                          </div>
                          <div className="wpo-choose-check-item">
                            <p>
                              Supervising Conversion or Retrofit Projects
                              Including Yacht
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="v-shape">
                      <img src="assets/images/v-shape.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarineSolutions;
