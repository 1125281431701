import React from 'react';
import src from '../../images/map.png';

const CustomImageSection = () => {
  return (
    <section className="custom-image-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-12 col-sm-12">
            <img 
              src={src} 
              alt="Custom Section" 
              className="img-fluid custom-image" 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomImageSection;
