import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import ShopProduct from '../../components/ShopProduct';
import api from "../../api";
import SubscribeSectionS2 from '../../components/SubscribeSectionS2/SubscribeSectionS2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/promarine.png';

// CSS for loading screen
const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '2rem',
    backgroundColor: '#f7f7f7'
};

const LoadingSpinner = () => (
    <div style={loadingStyle}>
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);

const categories = [
    "Two Stroke Diesel Engine",
    "Generator Sets/Aux. Engine",
    "Hatch Cover",
    "Boiler/Incinerator",
    "Seperator/Heat Exchanger",
    "Pump & Valve",
    "Turbocharger",
    "Air Compressor"
];

const ShopPage = ({ addToCart }) => {
    const productsArray = api();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [loading, setLoading] = useState(false); // Loading state
    const productsPerPage = 6;

    // Simulate API loading delay
    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // 1 second delay to simulate loading

        return () => clearTimeout(timer);
    }, [selectedCategory, currentPage]);

    // Filter products by category
    const filteredProducts = productsArray.filter(product => product.category === selectedCategory);
    const totalProducts = filteredProducts.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setLoading(true);
    };

    const currentProducts = filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1); // Reset to the first page when category changes
        setLoading(true);  // Show loading on category change
    };

    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={"wpo-site-header"} Logo={Logo} />
            <PageTitle pageTitle={'Product Center'} pagesub={'Product Center'} />

            {/* Category Buttons */}
            <div className="container my-4">
                <div className="row justify-content-center">
                    {categories.map((category) => (
                        <div key={category} className="col-6 col-md-auto mb-2">
                            <button
                                className={`btn btn-category ${selectedCategory === category ? 'active' : ''}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {/* Show Loading Spinner when loading */}
            {loading ? (
                <LoadingSpinner />
            ) : (
                <section className="wpo-shop-section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Animated product list */}
                                <div className="product-list fade-in">
                                    <ShopProduct
                                        products={currentProducts}
                                    />
                                </div>

                                {/* Pagination */}
                                <div className="d-flex justify-content-center pagination-wrapper pagination-wrapper-center">
                                    <ul className="pg-pagination">
                                        <li>
                                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="Previous">
                                                <i className="ti-angle-left"></i>
                                            </button>
                                        </li>
                                        {[...Array(totalPages)].map((_, index) => (
                                            <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                                <button onClick={() => handlePageChange(index + 1)}>
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                        <li>
                                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} aria-label="Next">
                                                <i className="ti-angle-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <Footer hclass={'wpo-site-footer'} upperContactArea={true} />
            <Scrollbar />
        </Fragment>
    );
};

export default connect(null)(ShopPage);
