import React, { Fragment, useRef } from "react";
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import Navbar from "../../components/Navbar/Navbar";
import FunFact from "../../components/FunFact/FunFact";
import About from "../../components/about/about";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/promarine.png";
import Hero3 from "../../components/hero3/Hero3";
import PartCard from "../4PartCard";
import Advantages from "../Advantages/Advantages";
import MarineSolutions from "../MarineSolutions";
import Contactpage from "../../components/Contactpage/Contactpage";
import CustomImageSection from "../../components/worldMap/worldMap";

const HomePage = () => {
  const sectionRef = useRef(); // Ref to target the section

  return (
    <Fragment>
      <HeaderTop />
      <Navbar hclass={"wpo-site-header"} Logo={Logo} />
      <Hero3 sectionRef={sectionRef} />
      <ProjectSection
          hclass={"wpo-project-section section-padding"}
          SectionTitleShow={true}
          projectBtn={true}
          ref={sectionRef}
        />
      <About hclass={"wpo-about-section section-padding"} />
      <Advantages />
      <MarineSolutions />
      <CustomImageSection />
      <FunFact hclass={"wpo-fun-fact-section"} />
      <PartCard />
      <PartnersSection CollClass={"col col-xs-12"} />
      <CtaSection />
      <Contactpage />
      <Footer hclass={"wpo-site-footer"} upperContactArea={true} />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
