import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";
import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";
import blogImg9 from "../images/blog/img-9.jpg";
import blogImg10 from "../images/blog/img-10.jpg";

import blogSingleImg1 from "../images/blog/img-11.jpg";
import blogSingleImg2 from "../images/blog/img-12.jpg";
import blogSingleImg3 from "../images/blog/img-13.jpg";

const blogs = [
  {
    id: "1",
    title: "We ensures you the best quality services",
    title2: "Express delivery is going to slow down in 2024.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy-Target-For-Cyberattack",
    screens: blogImg1,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Food and Beverages",
  },
  {
    id: "2",
    title: "Express delivery is going to slow down in 2024",
    title2: "Will supply chains become supply circles?",
    tag: "Orthopedic",
    slug: "Why-Industry-Are-A-Juicy-Target-For",
    screens: blogImg2,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg2,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Fresh Fish & Meat",
  },
  {
    id: "3",
    title: "Will supply chains become supply circles?",
    title2: "We ensures you the best quality services",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy",
    screens: blogImg3,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },

  {
    id: "4",
    title: "We ensures you the best quality services",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy4",
    screens: blogImg4,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
  {
    id: "5",
    title: "Express delivery is going to slow down in 2024",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy5",
    screens: blogImg5,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
  {
    id: "6",
    title: "Will supply chains become supply circles?",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy6",
    screens: blogImg6,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },

  {
    id: "7",
    title: "Will supply chains become supply circles?",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy7",
    screens: blogImg5,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },

  {
    id: "8",
    title: "We ensures you the best quality services",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy8",
    screens: blogImg7,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
  {
    id: "9",
    title: "Express delivery is going to slow down in 2022",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy9",
    screens: blogImg8,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Aliza Anny",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
  {
    id: "10",
    title: "Will supply chains become supply circles?",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy10",
    screens: blogImg9,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Anne William",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
  {
    id: "11",
    title: "It is a long established fact that a reader.",
    title2: "25 Rules and regulation to be successful in your business.",
    tag: "Surgery",
    slug: "Why-Industry-Are-A-Juicy11",
    screens: blogImg10,
    description:
      "It was with the release sheets containing passages more software.",
    author: "Thomas Rock",
    create_at: "Sep 03, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "MAR",
    blClass: "format-standard-image",
    animation: "1200",
    catagori: "Culture and Traditions",
  },
];
export default blogs;
