import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/promarinelogo_beyaz2.png";
import Services from "../../api/Services";
/* image */
import Img1 from "../../images/instragram/1.jpg";
import Img2 from "../../images/instragram/2.jpg";
import Img3 from "../../images/instragram/3.jpg";
import Img4 from "../../images/instragram/4.jpg";
import Img5 from "../../images/instragram/5.jpg";
import Img6 from "../../images/instragram/6.jpg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setError("");
      console.log("Email is valid:", email);
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const {
    upperContactArea = false,
    InstagramShow = false,
    NewsletterShow = true,
    FooterShape = true,
  } = props;

  return (
    <footer className={"" + props.hclass}>
      {upperContactArea && (
        <div className="upper-contact-area">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="contact-grids">
                  <div className="grid align-items-center">
                    <div className="icon">
                      <i className="fi flaticon-location-1"></i>
                    </div>
                    <div className="details" style={{ marginLeft: "3em" }}>
                      <h5>Location:</h5>
                      <p style={{ maxWidth: "75%", fontSize: "11px" }}>
                        Tuzla/İstanbul Evliya Çelebi, Nazan Sokağı No:12 D:3,
                        Tuzla/İstanbul
                      </p>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="icon">
                      <i className="fi flaticon-email"></i>
                    </div>
                    <div className="details">
                      <h5>E-Mail:</h5>
                      <p style={{ fontSize: "12px" }}>
                        info@promarinesolutions.com
                      </p>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="icon">
                      <i className="fi flaticon-phone-call"></i>
                    </div>
                    <div className="details">
                      <h5>Phone:</h5>
                      <p>+90 216 446 65 44</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={logo} alt="" width={196} />
                </div>
                <p>
                  When you choose Pro Marine Solutions, you get the guarantee of
                  high-quality spare parts services and reliability. We provide
                  tailored solutions to meet the unique needs of our customers
                  across various industries.
                </p>
                <div className="social-icons">
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="ti-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="ti-pinterest"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="#">
                        <i className="ti-vimeo-alt"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-12">
              <div className="widget-title">
                <h3>Quick Links</h3>
              </div>
              <div className="widget link-widget d-flex gap-5">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      News
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      Catalog
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="#">
                      Business Scope
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {NewsletterShow && (
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="widget newsletter-widget">
                  <div className="widget-title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>
                    Join our newsletter for news and innovations about Pro
                    Marine Solutions!
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="input-1">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="E-Mail Address *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {error && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error}
                      </div>
                    )}
                    <div className="submit clearfix">
                      <button type="submit">
                        <i className="ti-email"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {InstagramShow && (
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="widget instagram">
                  <div className="widget-title">
                    <h3>Our Gallery</h3>
                  </div>
                  <ul className="d-flex">
                    <li>
                      <img src={Img1} alt="" />
                    </li>
                    <li>
                      <img src={Img2} alt="" />
                    </li>
                    <li>
                      <img src={Img3} alt="" />
                    </li>
                    <li>
                      <img src={Img4} alt="" />
                    </li>
                    <li>
                      <img src={Img5} alt="" />
                    </li>
                    <li>
                      <img src={Img6} alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {FooterShape && (
          <div>
            <div className="vector-1"></div>
            <div className="vector-2"></div>
            <div className="vector-3"></div>
            <div className="vector-4"></div>
            <div className="vector-5"></div>
            <div className="vector-6"></div>
            <div className="vector-7"></div>
            <div className="vector-8"></div>
          </div>
        )}
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12  col-12">
              <p className="copyright">
                Copyright &copy;<span>2024</span>
                <span className="copyright-icon">|</span> All Rights Reserved.
              </p>
            </div>
            <div className="col-lg-6 col-md-12  col-12">
              <p className="link">
                <Link onClick={ClickHandler} to="/blog">
                  Term and Service
                </Link>{" "}
                <span>||</span>{" "}
                <Link onClick={ClickHandler} to="/blog">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
