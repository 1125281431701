import React, { Fragment, useState } from 'react';
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/promarine.png';
import { Worker, Viewer } from '@react-pdf-viewer/core'; // React PDF Viewer bileşenleri
import '@react-pdf-viewer/core/lib/styles/index.css'; // Stiller
import pdfUrl from '../../images/promarine-brosur.pdf'

const Brochure = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={"wpo-site-header"} Logo={Logo} />
            <PageTitle pageTitle={'Catalog'} pagesub={'Catalog'} />

            <div className="pdf-container">
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                    <div className="pdf-viewer">
                        <Viewer fileUrl={pdfUrl} />
                    </div>
                </Worker>
            </div>

            <Footer hclass={'wpo-site-footer'} upperContactArea={true} />
            <Scrollbar />
        </Fragment>
    );
};

export default Brochure;
