import React, { Fragment, useEffect } from "react";
import { scroller, Element } from "react-scroll"; // react-scroll import
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import CtaSection from "../../components/CtaSection/CtaSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/promarine.png";

import Image1 from "../../images/spare-part/image1.jpeg"; // Görseller
import Image2 from "../../images/spare-part/image2.jpeg";
import Image3 from "../../images/spare-part/image3.jpeg";
import Image4 from "../../images/spare-part/image4.jpeg"; // Görseller
import Image5 from "../../images/maintenance/image5.jpeg";
import Image6 from "../../images/maintenance/image6.jpeg";
import Image7 from "../../images/maintenance/image7.jpeg"; // Görseller
import Image8 from "../../images/maintenance/image8.jpeg";



const BusinessScopePage = () => {

  // URL'deki hash'e göre kaydırmayı gerçekleştirme
  useEffect(() => {
    const hash = window.location.hash.substring(1); // hash'ten # işaretini kaldır
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, []);

  // Butonlar ile animasyonlu kaydırma
  const handleScrollTo = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Fragment>
      <HeaderTop />
      <Navbar hclass={"wpo-site-header"} Logo={Logo} />
      <PageTitle pageTitle={"Business Scope"} pagesub={"Business Scope"} />
      <div className="container row row-cols-2 p-5 justify-content-center align-items-start gap-5">
        <div className="col-lg-3 col-md-6 col-8 row text-center">
          <button onClick={() => handleScrollTo("spare-parts-supply")} className="theme-btn">
            Spare Part Supply
          </button>
          <button onClick={() => handleScrollTo("maintenance")} className="theme-btn">
            Maintenance
          </button>
          <button onClick={() => handleScrollTo("technical-consultation")} className="theme-btn">
            Technical Consultation
          </button>
          <button onClick={() => handleScrollTo("international-logistics")} className="theme-btn">
            International Logistics
          </button>
        </div>
        <div className="col-lg-8 col-md-10 col-12 row gap-5">
          <Element id="spare-parts-supply" name="spare-parts-supply" className="d-flex flex-column">
            <h1>Spare Part Supply</h1>
            <p>
              Ship spare parts supply is crucial to ensure the uninterrupted
              operation of maritime activities. At Pro Marine Solutions, we
              specialize in providing the highest quality spare parts to our
              customers through our extensive product range and reliable supply
              network. Whether you need engine components, electrical systems,
              or mechanical parts, we deliver the necessary ship spare parts
              quickly and reliably. Thanks to our deep knowledge of ship spare
              parts and our long-standing experience in the industry, we ensure
              that we source the parts most suited to your ship’s operational
              needs in the shortest time possible. This not only enhances the
              operational efficiency of your ship but also minimizes breakdowns
              and disruptions. With our ship spare parts supply services, secure
              the continuity of your operations and stay ahead in the maritime
              industry.
            </p>
            <div className="image-container">
              <img src={Image1} alt="Spare Part 1" />
              <img src={Image2} alt="Spare Part 2" />
              <img src={Image3} alt="Spare Part 3" />
              <img src={Image4} alt="Spare Part 3" />
            </div>
          </Element>
          <Element id="maintenance" name="maintenance" className="d-flex flex-column">
            <h1>Maintenance</h1>
            <p>
              Ship maintenance services are indispensable for the continuity and
              safety of maritime operations. At Pro Marine Solutions, we offer
              comprehensive maintenance services designed to make your ship
              maintenance processes more efficient and to extend the lifespan of
              your vessel. Our maintenance team works in synergy with our ship
              spare parts supply to meet all your mechanical, electrical, and
              structural maintenance needs with the highest quality standards
              and at the most competitive costs. Our ship maintenance services
              not only secure your operational continuity but also minimize
              unexpected breakdowns and high repair costs. From the supply to
              the installation of ship spare parts, our expert team is by your
              side at every step, ensuring your ship runs smoothly. For safe
              voyages, entrust your maintenance needs to us.
            </p>
            <div className="image-container">
              <img src={Image5} alt="Spare Part 1" />
              <img src={Image6} alt="Spare Part 2" />
              <img src={Image7} alt="Spare Part 3" />
              <img src={Image8} alt="Spare Part 3" />
            </div>
          </Element>
          <Element id="technical-consultation" name="technical-consultation" className="d-flex flex-column">
            <h1>Technical Consultation</h1>
            <p>
              Benefit from our technical consultation services to make your ship
              operations more efficient and to find professional solutions to
              your technical problems. At Pro Marine Solutions, our team of
              experts with years of experience in the maritime industry offers
              comprehensive consultation services on ship spare parts selection,
              integration, and the optimization of operational processes. We
              guide you through every stage, from sourcing ship spare parts to
              diagnosing and solving technical problems. Our technical
              consultation services are designed to enhance your ship’s
              performance, ensure energy efficiency, and reduce your operational
              costs. Moreover, by keeping up with the latest technological
              advancements in the industry, we offer solutions most suited to
              your ship’s needs. At Pro Marine Solutions, our technical
              consultation services help you elevate your ship’s performance to
              the highest level.
            </p>
          </Element>
          <Element id="international-logistics" name="international-logistics" className="d-flex flex-column">
            <h1>International Logistics</h1>
            <p>
              International logistics services are vital for the safe and timely
              delivery of ship spare parts and equipment around the globe. At
              Pro Marine Solutions, we make a difference in spare parts
              logistics with our extensive logistics network in the maritime
              industry and strategic partnerships. Our team of experts in ship
              spare parts supply provides you with the most suitable logistics
              solutions to ensure that your parts reach their destination on
              time and safely. Our international logistics services cover all
              processes, from customs clearance to transportation, storage, and
              distribution. The smooth delivery of ship spare parts worldwide is
              critical to your operational continuity and efficiency. At Pro
              Marine Solutions, we meet all your logistics needs with the
              highest standards, ensuring that your spare parts reach you in the
              shortest time possible. If you’re looking for a reliable partner
              in ship spare parts logistics, our international logistics
              services are here for you.
            </p>
          </Element>
        </div>
      </div>
      <CtaSection />
      <PartnersSection CollClass={"col col-xs-12"} />
      <Footer hclass={"wpo-site-footer"} upperContactArea={true} />
      <Scrollbar />
    </Fragment>
  );
};

export default BusinessScopePage;
