/* Single image */
import img1 from "../images/service/img-1.jpg";
import img2 from "../images/service/img-2.jpg";
import img3 from "../images/service/img-3.jpg";
import img4 from "../images/service/img-4.jpg";
import img5 from "../images/service/img-5.jpg";
import img6 from "../images/service/img-6.jpg";
import img7 from "../images/service/img-7.jpg";
import img8 from "../images/service/img-8.jpg";
import img9 from "../images/service/img-9.jpg";
import img10 from "../images/service/img-10.jpg";
import img11 from "../images/service/img-11.jpg";

import Simg1 from "../images/service-single/img-1.jpg";
import Simg2 from "../images/service-single/img-2.jpg";
import Simg3 from "../images/service-single/img-3.jpg";
import Simg4 from "../images/service-single/img-4.jpg";
import Simg5 from "../images/service-single/img-5.jpg";
import Simg6 from "../images/service-single/img-6.jpg";
import Simg7 from "../images/service-single/img-7.jpg";
import Simg8 from "../images/service-single/img-8.jpg";
import Simg9 from "../images/service-single/img-9.jpg";
import Simg10 from "../images/service-single/img-10.jpg";
import Simg11 from "../images/service-single/img-11.jpg";

const Services = [
  {
    id: 1,
    image: img1,
    simag: Simg1,
    subtitle: "Transport",
    title: "Air Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Air-Transport",
  },
  {
    id: 2,
    image: img2,
    simag: Simg2,
    subtitle: "Transport",
    title: "Ocean Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Ocean-Transport",
  },
  {
    id: 3,
    image: img3,
    simag: Simg3,
    subtitle: "Transport",
    title: "Road Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Road-Transport",
  },
  {
    id: 4,
    image: img4,
    simag: Simg4,
    subtitle: "Transport",
    title: "Air Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Road-Transport2",
  },
  {
    id: 5,
    image: img5,
    simag: Simg5,
    subtitle: "Transport",
    title: "Ocean Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Ocean-Transport2",
  },
  {
    id: 6,
    image: img6,
    simag: Simg6,
    subtitle: "Transport",
    title: "Road Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Road-Transport3",
  },
  {
    id: 7,
    image: img7,
    simag: Simg7,
    subtitle: "Transport",
    title: "Train Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Train-Transport3",
  },
  {
    id: 8,
    icon: "fi flaticon-air-freight",
    image: img8,
    simag: Simg8,
    subtitle: "Transport",
    title: "Air Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Train-Transport8",
  },
  {
    id: 9,
    icon: "fi flaticon-delivery-truck",
    image: img9,
    simag: Simg9,
    subtitle: "Transport",
    title: "Ocean Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Train-Transport9",
  },
  {
    id: 10,
    icon: "fi flaticon-cruise",
    image: img10,
    simag: Simg10,
    subtitle: "Transport",
    title: "Road Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Train-Transport10",
  },
  {
    id: 11,
    icon: "fi flaticon-lighting",
    image: img11,
    simag: Simg11,
    subtitle: "Transport",
    title: "Train Transport",
    description:
      "It was popularised with the release sheets containing passages and more recently with software.",
    slug: "Train-Transport11",
  },
];
export default Services;
