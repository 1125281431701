import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pimg1 from '../../images/reference/hitachi_fitted.png';
import Pimg2 from '../../images/reference/hyundai_fitted.png';
import Pimg3 from '../../images/reference/daikin_fitted.png';
import Pimg4 from '../../images/reference/cat_fitted.png';
import Pimg5 from '../../images/reference/man_fitted.png';
import Pimg6 from '../../images/reference/abb_fitted.png';

const PartnersSection = (props) => {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    const { PatnarTitleShow = false } = props;

    return (
        <section className="partners-section section-padding">
            <h2 className="hidden">Partners</h2>
            <div className="container">
                <div className="row align-items-center">
                    {PatnarTitleShow && (
                        <div className="col-lg-2 col-12">
                            <h3>Worldwide <br />
                                <span>10,000+</span> Trusted partners.
                            </h3>
                        </div>
                    )}
                    <div className={"" + props.CollClass}>
                        <div className="partner-grids partners-slider">
                            <Slider {...settings}>
                                {[Pimg1, Pimg2, Pimg3, Pimg4, Pimg5, Pimg6].map((img, index) => (
                                    <div key={index} className="grid justify-content-center align-items-center">
                                        <img src={img} alt="" className="partner-logo" style={{height : "150px"}}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnersSection;
