import React, { useState } from "react";
import { Link } from "react-router-dom";
import Image1 from '../../images/slider/shape.png'
import Image2 from '../../images/slider/shape2.jpeg'

function Hero3({ sectionRef }) {
  const ClickHandler = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const slides = [
    {
      title: "Global Support in Spare Parts Maintenance",
      description:
        "Pro Marine Solutions offers reliable global solutions in spare parts, maintenance, and logistics for the maritime industry. We ensure your fleet operates at peak performance at all times.",
      button: "Discover",
      link: "/discover",
      backgroundImage: `url(${Image1})`,
    },
    {
      title: "Global Support in Spare Parts Maintenance",
      description:
        "Pro Marine Solutions offers reliable global solutions in spare parts, maintenance, and logistics for the maritime industry. We ensure your fleet operates at peak performance at all times.",
      button: "Discover",
      link: "/discover",
      backgroundImage: `url(${Image2})`,
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <>
      <section
        className="static-hero"
        style={{ backgroundImage: slides[currentSlide].backgroundImage }}
      >
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container">
              <div className="sliderContent">
                <div className="slide-title">
                  <h1 style={{ color: "white" }}>{slides[currentSlide].title}</h1>
                </div>
                <div className="slide-text">
                  <p>{slides[currentSlide].description}</p>
                </div>
                <div className="slide-btns">
                  <Link onClick={ClickHandler} to={slides[currentSlide].link} className="theme-btn">
                    {slides[currentSlide].button}
                  </Link>
                </div>
              </div>
              <div className="slider-controls">
                <button onClick={prevSlide} className="prev-btn">
                  &#10094;
                </button>
                <button onClick={nextSlide} className="next-btn">
                  &#10095;
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero3;
